<template>
  <div>
    <div class="s-news-nav">
      <div class="s-basis-1/4">
        <button class="s-news-btn ml-3 pl-3 pr-3" @click="back">返回</button>
      </div>
      <div class="text-center s-basis-1/2">{{ news.title }}</div>
    </div>
    <p v-if="fetching"><Loading /></p>
    <template v-else>
      <div class="news m-3 s-bg-white s-px-3 s-py-5">
        <div class="news__title">{{ news.title }}</div>
        <div class="news__subtitle">{{ news.subtitle }}</div>
        <img class="w-100 s-py-5" :src="news.image_url">
        <div v-html="news.content"></div>
      </div>
    </template>
  </div>
</template>

<script>
import Loading from '@/components/Page/Liff/Shared/Loading'
import newsApi from "@/apis/liff/v2/news";

export default {
  components: { Loading, },
  data: () => ({
    fetching: true,
    news: {},
  }),
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch () {
      this.fetching = true

      try {
        const { data } = await newsApi.get(this.$route.params.id)
        this.news = data
      } catch (e) {
        console.log(e)
      }

      this.fetching = false
    },
    back () {
      this.$router.push({
        name: 'LiffNewsIndex',
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .s-news-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
    background-color: var(--s-white);
  }

  .s-news-btn {
    color: var(--s-gray);
    border: 1px solid var(--s-gray);
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    padding: 2px 6px;
  }

  .s-text-primary-text {
    color: var(--liff-primary_text_color);
  }

  .s-text-black {
    color: var(--s-gray-darker);
  }

  .news {
    &__title,
    &__subtitle {
      font-weight: 600;
      line-height: 18px;
      min-height: 18px;
      color: #2c2c2e;
    }

    &__title {
      font-size: 24px;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 18px;
      margin-bottom: 8px;
    }
  }
</style>
